.mobile-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  
    img {
      max-width: 90%;
      max-height: 90%;
    }
  }
  