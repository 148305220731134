@font-face {
  font-family: "Adam Warren Pro";
  src: url("../fonts/adam-warren/adamwarrenpro-bold.ttf") format("truetype"),
    url("../fonts/adam-warren/adamwarrenpro-bolditalic.ttf") format("truetype"),
    url("../fonts/adam-warren/adamwarrenpro.ttf") format("truetype");
}

@font-face {
  font-family: "Gobold";
  src: url("../fonts/gobold/Gobold Bold Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Bold.otf") format("opentype"),
    url("../fonts/gobold/Gobold CUTS Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold CUTS.otf") format("opentype"),
    url("../fonts/gobold/Gobold Extra1 Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Extra1.otf") format("opentype"),
    url("../fonts/gobold/Gobold Extra2 Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Extra2.otf") format("opentype"),
    url("../fonts/gobold/Gobold High Bold Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold High Bold.otf") format("opentype"),
    url("../fonts/gobold/Gobold High Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold High Thin Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold High Thin.otf") format("opentype"),
    url("../fonts/gobold/Gobold High.otf") format("opentype"),
    url("../fonts/gobold/Gobold Hollow Bold Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Hollow Bold.otf") format("opentype"),
    url("../fonts/gobold/Gobold Hollow Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Hollow.otf") format("opentype"),
    url("../fonts/gobold/Gobold Light Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Light.otf") format("opentype"),
    url("../fonts/gobold/Gobold Lowplus Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Lowplus.otf") format("opentype"),
    url("../fonts/gobold/Gobold Regular Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Regular.otf") format("opentype"),
    url("../fonts/gobold/Gobold Thin Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Thin.otf") format("opentype"),
    url("../fonts/gobold/Gobold Uplow Italic.otf") format("opentype"),
    url("../fonts/gobold/Gobold Uplow.otf") format("opentype");
}

:root {
  --font: "Gobold", sans-serif;
  --font-comic: "Adam Warren Pro";
}
