@import 'utils', 'resets';

body {
  background-color: #000;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/cortina.png');
    background-size: contain;
    opacity: .5;
    z-index: -1;
  }
}